
.description-section {
  &-title {
    background-color: #0C534D;
    text-align: center;
    color: #ffffff;
    padding: 50px 0;

    h2 {
      font: normal normal 900 46px/45px Roboto;
      margin: 0;
      @media (max-width: 991px) {
        font-size: 32px; } }

    h3 {
      font: normal normal 900 30px/45px Roboto;
      margin: 0;
      @media (max-width: 991px) {
        font-size: 22px;
        line-height: 1.5; }
      @media (max-width: 767px) {
        font-size: 18px; } } }

  &-content {
    position: relative;
    .content {
      &-background {
        position: absolute;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top left;
          @media (max-width: 767px) {
            object-position: 13% 0; } } }

      &-detail {
        color: #ffffff;
        padding: 100px 0;
        text-align: left;
        @media (max-width: 767px) {
          padding: 40px 0; }

        p {
          font: normal normal normal 18px/35px Roboto; }

        h2 {
          font: normal normal 900 50px/55px Roboto;
          margin-bottom: 0;
          margin-top: 20px;
          @media (max-width: 767px) {
            font-size: 37px;
            line-height: 1.4; } } } } } }


