.suryanation-page {
  .slide {
    &-wrapper {
      &[data-slide="1"] {
        [data-aos^=zoom][data-aos^=zoom] {
          opacity: 1!important; }
        ._background {
          img {
            @media (max-width: 991px) {
              object-position: 25%; } } } }

      &[data-slide="2"] {
        .slide-deco {
          right: 0;
          width: 50%;
          @media (max-width: 991px) {
            width: 100%; }
          img {
            object-position: right bottom;
            @media (max-width: 991px) {
              filter: blur(2px); } } }

        [data-aos=fade-left] {
          transform: translate3d(50vw, 0, 0); } }

      &[data-slide="3"] {
        .pl-5 {
          padding-left: 0!important; }

        .slide-deco {
          width: 53%;
          margin-bottom: -2%;
          @media (max-width: 991px) {
            width: 100%;
            margin: 0;
            img {
              filter: blur(2px); } }

          &-logo {
            position: absolute;
            top: 12%;
            left: 20%;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain; } } }

        [data-aos=fade-right] {
          transform: translate3d(-50vw, 0, 0); } }

      &[data-slide="4"] {
        ._background {
          left: 11vw;
          top: 8vw;
          @media (max-width: 991px) {
            left: 0;
            top: 0;

            img {
              object-position: 65%; } } } }

      &[data-slide="5"] {
        ._background {
          width: calc(100% + 100px);

          img {
            object-position: bottom left; } } } } } }
