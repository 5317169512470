.detail-works-page {
  background-color: #000000;
  .footer {
    @media (max-width: 991px) {
      position: fixed; }
    @media (max-width: 767px) {
      display: none;
      &._mobile {
        display: block;
        position: relative; } } }
  .slide-scene {
    display: block;
    @media (max-width: 767px) {
      display: none; } }
  .detail-mobile-scene {
    display: none;
    @media (max-width: 767px) {
      display: block; } }

  .pl-4, .pl-5 {
    @media (max-width: 991px) {
      padding-left: 15px!important; } }

  .pr-5 {
    @media (max-width: 991px) {
      padding-right: 15px!important; } }

  .color-gold {
    color: #F0C986!important; }

  .slide-arrow {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    background-color: #fff;
    z-index: 99;
    bottom: 5vw;
    right: 5vw;
    transition: 0.4s;

    &:hover {
      transform: scale(1.05); }
    &.arrow-prev {
      transform: translateY(-60px);
      &:hover {
        transform: translateY(-60px) scale(1.05); } }

    .svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
      fill: #FF4A35; } }

  .button-link {
    background-color: #313131;
    text-align: center;
    padding: 30px;
    @media (max-width: 767px) {
      padding: 15px; }

    &-wrapper {
      background-color: #270501;
      padding-bottom: 100px;
      @media (max-width: 991px) {
        padding-bottom: 0; } }

    a {
      color: #ffffff;
      text-decoration: none;
      font: normal normal bold 29px/34px Roboto;
      @media (max-width: 767px) {
        font-size: 20px;
        line-height: 1.3; }

      &:hover {
        color: #01FFE8; } } }

  .full-height {
    height: 100vh!important;
    min-height: 768px;
    @media (max-width: 991px) {
      height: auto!important;
      min-height: auto; } }

  .slide {
    &-wrapper {
      position: relative;
      overflow: hidden;
      @media (max-width: 991px) {
        overflow-x: hidden; }

      ._background {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          @media (max-width: 991px) {
            filter: blur(2px); } } }

      ._content {
        position: relative;
        @media (max-width: 991px) {
          padding: 80px 0; }

        &-wrapper {
          height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ffffff;
          @media (max-width: 991px) {
            min-height: 100vh;
            height: auto; }
          // @media (max-width: 767px)
          //   min-height: auto

          h1 {
            font: normal normal 900 65px/65px Roboto;
            @media (max-width: 991px) {
              font-size: 50px;
              line-height: 1; } }

          h2 {
            font: normal normal 900 50px/65px Roboto; }

          p {
            font: normal normal 300 18px/30px Roboto; }

          small {
            font: normal normal 300 14px/30px Roboto; } } }

      .slide-deco {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        bottom: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain; } } } }

  .works-link {
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: #A70000;
      mix-blend-mode: multiply; }

    ._background {
      z-index: -1; }

    ._content-wrapper {
      height: auto;
      padding: 20px 0 80px;
      display: block;
      text-align: center;
      @media (max-width: 991px) {
        min-height: auto;
        padding: 80px 0 120px; }
      @media (max-width: 767px) {
        padding: 40px 0; } }

    .button-wrapper {
      margin: 20px 0;
      display: block;
      a {
        display: inline-block;
        min-width: 200px;
        font: normal normal 500 15px/17px Gotham Pro;
        text-decoration: none; } }

    .works-list {
      .col-12 {
        padding: 15px; }
      img {
        object-fit: cover; } } }

  .slide {
    &-wrapper {
      ._content {
        width: 100%; } } } }

html.fp-enabled,
.fp-enabled body {
  @media (max-width: 767px) {
    overflow: visible!important;
    height: auto!important; } }
.normal-scroll {
  position: relative;
  // max-height: 100vh
  // overflow-y: scroll
  // @media (max-width: 767px)
  //   overflow: visible
 }  //   max-height: none
.fp-auto-height {
  .fp-tableCell {
    .fp-scrollable {
      height: auto!important; } } }
// .fullpage-wrapper
//   @media (max-width: 767px)
//     height: auto!important

// .fp-tableCell
//   @media (max-width: 767px)
//     height: auto!important
//     display: block
// .fp-scroller
//   @media (max-width: 767px)
//     overflow: visible

// .fp-scrollable
//   @media (max-width: 767px)
//     height: auto!important
//     overflow: visible

// .fp-section.fp-table,
// .fp-slide.fp-table
//   @media (max-width: 767px)
//     display: block
//     height: auto!important

// .works-link
//   position: relative


// .fp-auto-height .fp-tableCell .fp-scrollable
//   height: auto!important
