.pcj-page {
  .normal-scroll {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  .slide {
    &-wrapper {
      &[data-slide="1"] {
        background-color: #F20924;
        margin-bottom: -10%;
        @media (max-width: 991px) {
          margin-bottom: -15%; }
        @media (max-width: 767px) {
          margin-bottom: -20%; }
        ._background {
          width: auto;
          img {
            object-position: left;
            @media (max-width: 991px) {
              opacity: 0.7; } } }

        .key-visual {
          width: 55vw;
          position: absolute;
          bottom: 20px;
          @media (max-width: 991px) {
            width: 100%;
            bottom: 0; }

          img {
            object-fit: contain;
            height: 100%;
            width: 100%;
            object-position: bottom right;
            @media (max-width: 991px) {
              filter: blur(2px); } } }

        ._content-wrapper {
          z-index: 1;
          position: relative; }

        .col-lg-5 {
          @media (min-width: 992px) {
            flex: 0 0 45%;
            max-width: 45%; } }


        h1 {
          font: normal normal 900 85px/75px Roboto;
          @media (max-width: 767px) {
            font-size: 65px;
            line-height: 1; } }

        p {
          font: normal normal 300 18px/30px Roboto;
          b {
            font: normal normal bold 18px/30px Roboto; }
          small {
            font: normal normal 300 14px/30px Roboto; } } }

      &[data-slide="2"] {
        margin-bottom: -10%;
        @media (max-width: 991px) {
          margin-bottom: -20%; }
        ._background {
          top: auto;
          bottom: 0;
          padding-top: 30px;
          img {
            object-position: top right;
            @media (max-width: 991px) {
              filter: none;
              object-position: top left; } }
          &-shadow {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            mix-blend-mode: multiply;
            @media (max-width: 991px) {
              top: auto;
              bottom: 0; }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: top right;
              @media (max-width: 991px) {
                object-position: top left; } } } }


        ._content {
          padding-top: 10%;
          height: 100%;
          overflow: hidden;
          @media (max-width: 991px) {
            height: 100vh;
            padding-top: 20%; }
          @media (max-width: 767px) {
            height: auto;
            padding-top: 150px;
            min-height: 100vh; }
          &-wrapper {
            text-align: center; }
          &-text {
            position: relative;
            z-index: 1; }


          &-image {
            text-align: center;
            @media (max-width: 991px) {
              position: absolute;
              bottom: 0;
              width: 100%; }

            img {
              max-width: 80%;
              @media (max-width: 991px) {
                max-width: 100%;
                filter: blur(2px); } } }

          h3 {
            font: normal normal 300 30px/75px Cera Pro;
            @media (max-width: 991px) {
              font-size: 25px; }
            @media (max-width: 767px) {
              font-size: 20px;
              line-height: 1; } }
          h1 {
            font: normal normal 900 45px/45px Cera Pro;
            @media (max-width: 991px) {
              font-size: 40px; }
            @media (max-width: 767px) {
              font-size: 30px;
              line-height: 1.2; } }

          ._list {
            border: 3px solid #FFFFFF;
            border-radius: 10px;
            padding: 10px 20px;
            font: normal normal normal 18px/25px Roboto;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            &-content {
              margin-top: 20px;
              @media (max-width: 991px) {
                .col-12 {
                  margin-bottom: 20px; } } } } } }

      &[data-slide="3"] {
        margin-bottom: -10%;
        @media (max-width: 991px) {
          margin-bottom: -20%; }
        ._content {
          padding-top: 60px;
          @media (max-width: 991px) {
            padding-top: 150px;
            padding-bottom: 40px;
            height: auto;
            &-wrapper {
              min-height: auto;
              height: 100%; } }
          @media (max-width: 767px) {
            &-wrapper {
              min-height: auto;
              height: auto; } } }

        .years-content-slider {
          .slider {
            text-align: center;
            margin: 60px 0;
            @media (max-width: 991px) {
              .col-12 {
                margin: 40px 0; } }
            @media (max-width: 767px) {
              margin-bottom: 0;
              .col-12 {
                margin: 40px 0 0; } }

            h4 {
              font: normal normal 900 25px/1 Roboto;
              @media (max-width: 767px) {
                font-size: 20px; }
              span {
                color: #31FF49;
                font: normal normal 900 28px/1 Roboto;
                @media (max-width: 767px) {
                  font-size: 25px; } } }
            h3 {
              font: normal normal 900 45px/59px Roboto;
              color: #31FF49;
              margin-bottom: 0;
              margin-top: 10px;
              @media (max-width: 767px) {
                font-size: 30px;
                line-height: 1; } }

            p {
              margin: 0;
              font: normal normal 400 14px/5px Roboto;
              @media (max-width: 767px) {
                line-height: 1.3; } } } }

        .slider-wrapper {
          position: relative;
          .frame-slide {
            width: 40%;
            border-radius: 19px;
            border: 4px solid #ffffff;
            height: 160px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            margin: auto;
            @media (max-width: 767px) {
              border: 2px solid #ffffff;
              height: calc(100% + 30px);
              top: 65%; }


            &:before {
              content: '';
              width: 0px;
              height: 0px;
              border-left: 16px solid transparent;
              border-right: 16px solid transparent;
              border-top: 25px solid #ffffff;
              position: absolute;
              left: 0;
              right: 0;
              margin: auto;
              top: 0; }

            &:after {
              content: '';
              width: 0px;
              height: 0px;
              border-left: 9px solid transparent;
              border-right: 9px solid transparent;
              border-bottom: 15px solid #ffffff;
              position: absolute;
              left: 0;
              right: 0;
              margin: auto;
              bottom: 0; } } }

        .years-slider {
          position: relative;
          opacity: 0;
          visibility: hidden;
          &.slick-initialized {
            opacity: 1;
            visibility: visible; }
          .slick-track {
            display: flex !important; }

          .slick-slide {
              height: inherit !important;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center; }
          .slider {
            text-align: center;
            padding-top: 30px;
            .dots {
              width: 12px;
              height: 12px;
              border-radius: 50%;
              background-color: #ffffff;
              opacity: 0.5;
              margin-bottom: 25%;
              @media (max-width: 767px) {
                margin-bottom: 15%; } }
            h3 {
              font: normal normal 900 40px/45px Roboto;
              margin: 0;
              @media (max-width: 767px) {
                font-size: 25px;
                line-height: 1; } }

            p {
              font: normal normal 400 20px/45px Roboto;
              margin: 0;
              opacity: 0;
              visibility: hidden;
              transition: 0.3s;
              @media (max-width: 767px) {
                font-size: 14px;
                line-height: 1; } }

            &.slick-center {
              h3 {
                font-size: 75px;
                @media (max-width: 767px) {
                  font-size: 45px; } }
              p {
                opacity: 1;
                visibility: visible; } } }

          .slick-list {
            padding: 0!important; }

          .slick-arrow {
            background-color: transparent;
            border: none;
            padding: 0;
            position: absolute;
            right: -50px;
            top: 50%;
            transform: translateY(-50%);
            outline: none!important;
            z-index: 1;
            cursor: pointer;
            @media (max-width: 991px) {
              right: 0; }
            @media (max-width: 767px) {
              top: 60%; }

            i {
              display: block;
              width: 26px;
              height: 95px;
              background-position: 0 0;
              background-repeat: no-repeat;
              background-size: contain;
              margin: 0 auto;
              overflow: hidden;
              text-indent: -999px;
              background-image: image('slick-arrow.svg');
              @media (max-width: 767px) {
                width: 20px;
                height: 60px; } }
            &:hover {
              i {
                background-image: image('slick-arrow-hover.svg'); } }

            &.slick-prev {
              right: auto;
              left: -50px;
              @media (max-width: 991px) {
                left: 0; }
              i {
                transform: rotate(180deg); } }

            &.slick-disabled {
              opacity: 0.5;
              &:hover {
                i {
                  background-image: image('slick-arrow.svg'); } } } } }


        ._background {
          top: auto;
          bottom: 0;

          img {
            object-position: top right;
            @media (max-width: 991px) {
              filter: none; } }

          &-shadow {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            mix-blend-mode: multiply;

            @media (max-width: 991px) {
              top: auto;
              bottom: 0; }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: top right; } } } }

      &[data-slide="4"] {
        .p-0 {
          @media (max-width: 767px) {
            padding: 0 15px!important; } }

        ._background {
          top: auto;
          bottom: 0;
          img {
            object-position: top center;
            @media (max-width: 991px) {
              filter: none; } }
          &-shadow {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            mix-blend-mode: multiply;
            @media (max-width: 991px) {
              top: auto;
              bottom: 0; }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: top center; } } }

        ._content {
          padding-top: 22%;
          @media (max-width: 767px) {
            padding-top: 30%;
            padding-bottom: 0; }
          &-wrapper {
            display: block;
            text-align: center;
            @media (max-width: 991px) {
              min-height: auto; } }
          p {
            font: normal normal 500 18px/32px Roboto; }

          .trigger-play {
            width: 68px;
            height: 68px;
            border-radius: 50%;
            background-color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 9999;
            cursor: pointer;
            img {
              transition: 0.3s;
              margin-left: 5px; }
            &:hover {
              img {
                transform: scale(1.2); } } } } }

      &[data-slide="5"] {
        background-color: #B7071B;
        .thankyou-image {
          padding: 60px 0;
          @media (max-width: 767px) {
            padding: 20px 15px; } }
        .works-link {
          position: relative;
          z-index: 1; }
        ._content-wrapper {
          @media (max-width: 991px) {
            padding-top: 20px; } } } } } }
