.waktunya-kebaikan-page {
  .normal-scroll {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  .slide {
    &-wrapper {
      &[data-slide="1"] {
        background-color: #e31f15;
        ._background {
          @media (max-width: 991px) {
            background-color: #1F1F1F;
            width: calc(100% + 100px); }
          img {
            @media (max-width: 991px) {
              opacity: 0.7;
              object-position: 30%; } } }

        h2 {
          font: normal normal normal 28px/33px Roboto;
          margin: 15px 0; }

        ._content {
          padding-bottom: 60px;
          width: 100%; } }

      &[data-slide="2"] {
        background-color: #FF4A35;
        h2 {
          font: normal normal 300 45px/53px Roboto;
          display: flex;
          align-items: center;
          @media (max-width: 767px) {
            font-size: 30px;
            line-height: 1.4; }
          > img {
            margin-right: 15px; } }
        p {
          font: normal normal normal 20px/28px Roboto;
          @media (max-width: 767px) {
            font-size: 18px;
            list-style: 1.4; } }

        ._content {
          &-wrapper {
            padding: 90px 0 0;
            @media (max-width: 991px) {
              padding: 0; } } }

        .deco-person {
          margin-bottom: -13%;
          margin-top: -2%; }
        .deco-phone {
          position: absolute;
          width: 100%;
          bottom: -40px;
          @media (max-width: 991px) {
            position: relative;
            bottom: auto; } }

        .content-box {
            box-shadow: 6px 6px 9px #0000005A;
            border-radius: 8px;
            background-color: #ffffff;
            padding: 40px;
            color: #DE0404;
            @media (max-width: 991px) {
              padding-bottom: 0;
              .row {
                flex-direction: column-reverse; } } } }

      &[data-slide="3"] {
        background-color: #FF4A35;
        ._content {
          &-wrapper {
            padding: 60px 0;
            @media (max-width: 991px) {
              padding: 0; } } }
        h3 {
          font: normal normal 300 45px/53px Roboto;
          position: absolute;
          left: 0;
          right: 0;
          margin-top: 30px;
          @media (max-width: 991px) {
            position: relative;
            margin-top: 0; } }
        ._background {
          img {
            object-position: top center; } } } } } }

