.brands {
  &-section {
    padding: 60px 0;
    background-color: #C1C1C1;
    h2 {
      margin-top: 0;
      text-align: center;
      font: normal normal bold 35px/41px Roboto;
      margin-bottom: 30px; }

    .button-link {
      padding: 0;
      background-color: transparent;
      a {
        color: #2D2D2D;
        display: block;
        padding: 15px;
        background-color: #fff;
        &:hover {
          color: #2D2D2D;
          background-color: #01FFE8; } } } }

  &-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px 30px;
    @media (max-width: 767px) {
      justify-content: center; }

    &-image {
      padding: 15px;
      flex: 0 0 20%;
      max-width: 20%;
      @media (max-width: 767px) {
        flex: 0 0 33.33%;
        max-width: 33.33%;
        padding: 0 15px; }
      img {
        width: 100%;
        filter: grayscale(100%);
        mix-blend-mode: multiply;
        transition: 0.4s;
        &:hover {
          filter: grayscale(0); } } } } }
