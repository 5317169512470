.la-smm-page {
  .normal-scroll {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  .slide {
    &-wrapper {
      &[data-slide="1"] {
        background-color: #050505;
        ._background {
          img {
            object-position: right; } }

        .container {
          position: relative;
          z-index: 1; }

        [data-aos^=zoom][data-aos^=zoom] {
          opacity: 1; }

        [data-aos=fade-up-right] {
          transform: translate3d(-30%, 100%, 0); }

        ._content {
          @media (max-width: 991px) {
            padding-top: 0; }
          @media (max-width: 767px) {
            text-align: center;
            padding: 0; } }
        ._title-event {
          h1 {
            font-weight: 900;
            font-size: 84px;
            line-height: 1.2;
            color: #FFFFFF;
            margin: 10px 0 0;
            @media (max-width: 767px) {
              font-size: 45px; } } }

        ._main-image {
          position: absolute;
          bottom: 0;
          right: 5%;
          width: 45%;
          @media (max-width: 991px) {
            width: 100%;
            right: 0; }

          img {
            width: 100%;
            @media (max-width: 991px) {
              filter: blur(2px); } } } }

      &[data-slide="2"] {
        [data-aos=fade-left] {
          transform: translate3d(50vw, 0, 0);
          @media (max-width: 991px) {
            transform: translate3d(100vw, 0, 0); } }

        ._content {
          img {
            width: 100%; } } }

      &[data-slide="3"] {

        background-color: #D50B0B;
        padding: 60px 0;
        @media (max-width: 767px) {
          padding: 0; }

        ._content {
          img {
            width: 100%; } }
        [data-aos^=zoom][data-aos^=zoom] {
          opacity: 1; } }

      &[data-slide="6"] {
        background-color: #9A1515; } } } }
