.la-event-page {
  .normal-scroll {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  .slide {
    &-wrapper {
      &[data-slide="1"] {
        background-color: #050505;
        ._background {
          padding-top: 80px;
          @media (max-width: 991px) {
            padding-top: 0; }

          img {
            @media (max-width: 991px) {
              object-position: left; } } }

        [data-aos^=zoom][data-aos^=zoom] {
          opacity: 1; }

        [data-aos=fade-up] {
          transform: translate3d(0, 50vh, 0); }

        [data-aos=fade-down] {
          transform: translate3d(0, -50vh, 0); }

        ._logo {
          position: absolute;
          top: 32%;
          width: 60vw;
          right: 0;
          text-align: center;
          @media (max-width: 991px) {
            width: 100%; }
          img {
            max-width: 280px; } }
        ._title-event {
          display: none;
          @media (max-width: 991px) {
            display: block; }
          h1 {
            margin: 0;
            color: #ffffff;
            font-weight: 900;
            font-size: 100px;
            line-height: 1;
            text-align: center;
            color: #EA1616;
            font-style: italic;
            margin-top: 20px;
            @media (max-width: 767px) {
              font-size: 60px;
              margin-top: 15px; } } }

        ._title {
          position: absolute;
          bottom: 28%;
          width: 60vw;
          right: 0;
          text-align: center;
          @media (max-width: 1366px) {
            bottom: 25%; }
          @media (max-width: 991px) {
            width: 100%; }
          h3 {
            margin: 0;
            color: #ffffff;
            font-weight: bold;
            font-size: 60px;
            line-height: 72px;
            @media (max-width: 767px) {
              font-size: 40px;
              line-height: 1;
              margin-top: 5px; } } }

        ._wrapper {
          @media (max-width: 991px) {
            position: relative;
            height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            ._title,
            ._logo {
              position: relative;
              top: auto;
              bottom: auto; } } } }

      &[data-slide="2"] {
        [data-aos=fade-left] {
          transform: translate3d(50vw, 0, 0); }
        ._content {
          text-align: center; } }

      &[data-slide="3"] {
        background-color: #050505;
        [data-aos=fade-down] {
          transform: translate3d(0, -50vh, 0); }
        ._content-wrapper {
          display: block;
          padding-top: 100px;
          height: auto;
          @media (max-width: 991px) {
            min-height: auto;
            padding-top: 0;
            ._content {
              padding: 0; } }
          @media (max-width: 767px) {
            padding-top: 60px; } }

        .images-wrapper {
          max-width: 1440px;
          margin: auto;
          @media (max-width: 991px) {
            max-width: 720px; }
          img {
            width: 100%;
            @media (max-width: 767px) {
              margin-bottom: 20px; } } }

        ._subtitle {
          margin-top: 70px;
          display: flex;
          justify-content: space-between;
          @media (max-width: 991px) {
            display: block;
            text-align: center;
            margin-top: 30px; }
          img {
            margin-bottom: 30px;
            @media (max-width: 991px) {
              width: auto; }
            @media (max-width: 767px) {
              width: 100%;
              height: 25px;
              object-fit: contain; } } }

        ._title {
          text-align: center; } }

      &[data-slide="4"] {
        background: linear-gradient(359.83deg, rgba(255, 255, 255, 0) 0.15%, #464646 99.85%);
        padding: 60px 0 100px;
        @media (max-width: 991px) {
          padding-bottom: 150px; }
        @media (max-width: 767px) {
          padding-bottom: 60px; }
        ._title {
          text-align: center;
          margin-bottom: 35px;
          @media (max-width: 767px) {
            img {
              width: 100%;
              height: 30px;
              object-fit: contain; } } }

        .images-wrapper {
          max-width: 1440px;
          margin: auto;
          @media (max-width: 991px) {
            max-width: 720px; }
          > .row {
            margin: 0 -5px;
            @media (max-width: 767px) {
              margin: 0 -15px; }
            .col-md-6, .col-lg-3 {
              padding: 5px; }
            img {
              width: 100%;
              @media (max-width: 767px) {
                margin-bottom: 20px; } } } } }

      &[data-slide="5"] {
        background-color: #050505;
        ._background {
          @media (max-width: 767px) {
            position: relative; }
          img {
            filter: none; } } }
      &[data-slide="6"] {
        background-color: #9A1515; } } } }
