.sitemap {
  &-section {
    padding: 150px 0 60px;
    min-height: calc(100vh - 30px);
    background-color: #313131;
    @media (max-width: 767px) {
      padding-top: 100px; }

    &-list {
      list-style: none;
      margin: 0;
      padding: 0;
      flex-direction: column;
      flex-wrap: wrap;
      display: flex;
      height: 200px;
      @media (max-width: 991px) {
        height: auto; }

      li {
        padding: 12px;
        flex: 0 0 25%;
        @media (max-width: 991px) {
          flex: none;
          margin-bottom: 10px; } }

      a {
        color: #7E7E7E;
        text-decoration: none;
        font: normal normal normal 18px/20px Roboto;
        display: block;
        text-transform: uppercase;
        &:hover {
          color: #ffffff; } } } } }
