.works-list {
  &-section {
    position: relative;

    &.content-position-right {
      img {
        @media (max-width: 991px) {
          object-position: 15% 0; } } } }

  &-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    @media (max-width: 991px) {
      background-color: #1F1F1F; }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @media (max-width: 991px) {
        object-position: 85% 0; }
      @media (max-width: 767px) {
        filter: blur(2px);
        transform: scale(1.02); } } }

  &-content {
    padding: 80px 0 160px;
    color: #ffffff;
    @media (max-width: 991px) {
      padding-bottom: 100px; }
    @media (max-width: 767px) {
      padding: 50px 0 100px; }

    h2 {
      font: normal normal 900 50px/60px Roboto;
      @media (max-width: 991px) {
        font-size: 35px;
        line-height: 1.3; } }

    p {
      font: normal normal normal 20px/24px Roboto;
      @media (max-width: 991px) {
        font-size: 16px;
        line-height: 1.3; } }

    small {
      font: normal normal 500 12px/20px Gotham Pro; }

    .button-action {
      margin-top: 20px;

      a {
        border-radius: 8px;
        border: 1px solid #ffffff;
        font: normal normal 500 15px/17px Gotham Pro;
        color: #ffffff;
        text-decoration: none;
        padding: 6px 15px;
        display: inline-block;
        &:hover {
          background-color: #fff;
          color: #1F1F1F; } } } } }
