.works-page {
  .fixed-background {
    img {
      object-position: 0 20%; } }

  .fixed-content {
    padding: 100px 0 20px;

    h1 {
      font: normal normal 900 100px/117px Roboto;
      text-align: center;
      @media (max-width: 991px) {
        font-size: 50px;
        line-height: 1.3; }
      @media (max-width: 767px) {
        font-size: 35px; } } } }

@import 'works/list';
