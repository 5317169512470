.tph-works-page {
  .fixed-background {
    background-color: #1F1F1F;
    @media (max-width: 767px) {
      overflow: hidden; }
    &:before {
      display: none; }

    img {
      object-position: top right;
      @media (max-width: 991px) {
        opacity: 0.7; }
      @media (max-width: 767px) {
        filter: blur(2px);
        transform: scale(1.02); } } }

  .fixed-content {
    padding: 160px 0;
    color: #ECF4F7;
    @media (max-width: 991px) {
      padding: 140px 0 100px; }

    h1 {
      font: normal normal 900 75px/62px Roboto;
      margin-bottom: 20px;
      @media (max-width: 991px) {
        font-size: 50px;
        line-height: 1.3; }
      @media (max-width: 767px) {
        font-size: 35px; } }

    p {
      font: normal normal normal 20px/24px Roboto; }

    small {
      font: normal normal 500 12px/23px Gotham Pro; } }

  .works-list-background {
    @media (max-width: 767px) {
      overflow: hidden; }
    img {
      @media (max-width: 991px) {
        opacity: 0.7; } } }

  .works-section-wrapper {
    background-color: #FF4A35;
    position: relative;
    > .container {
      transform: translateY(-150px);
      @media (max-width: 991px) {
        transform: translateY(-90px); } } }

  .works-list-content {
    padding: 60px;
    @media (max-width: 767px) {
      padding: 30px; }

    img {
      width: 100%;
      max-width: 370px; }

    h3 {
      font: normal normal bold 30px/33px Roboto;
      margin: 25px 0; }
    p {
      font-size: 18px; } }

  .works-list-section:first-child {
    .works-list-content {
      padding: 160px 60px;
      @media (max-width: 767px) {
        padding: 80px 30px; } } }

  .button-link {
    display: block;
    padding: 30px;
    background-color: #2D2D2D;
    text-align: center;
    @media (max-width: 767px) {
      padding: 15px; }

    a {
      color: #ffffff;
      text-decoration: none;
      font: normal normal bold 29px/34px Roboto;
      @media (max-width: 767px) {
        font-size: 20px;
        line-height: 1.3; }

      &:hover {
        color: #01FFE8; } } } }
