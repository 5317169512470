.about-page {
  .masthead {
    &-background {
      height: 525px;
      @media (max-width: 991px) {
        height: 350px; }
      @media (max-width: 767px) {
        height: 525px; }
      img {
        height: 100%;
        object-position: center bottom;
        @media (max-width: 767px) {
          object-position: 88% 0; } } }

    &-content {
      &-inner {
        @media (max-width: 991px) {
          top: 40%;
          left: 10%; }
        @media (max-width: 767px) {
          left: 15px;
          right: 15px;
          top: 20%; } }
      h1 {
        @media (max-width: 991px) {
          font-size: 58px;
          line-height: 1.1; }
        em {
          @media (max-width: 991px) {
            font-size: 42px;
            line-height: 1.1; } } } } } }
