.homepage {
  .button-link {
    background-color: #313131;
    text-align: center;
    padding: 15px;
    a {
      color: #ffffff;
      text-decoration: none;
      font: normal normal bold 29px/34px Roboto;
      @media (max-width: 767px) {
        font-size: 20px;
        line-height: 1.3; }
      &:hover {
        color: #01FFE8; } } } }

@import 'homepage/masthead';
@import 'homepage/services';
@import 'homepage/works';
@import 'homepage/brands';
