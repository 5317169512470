.works {
  &-section {
    position: relative;
    &-thumbnail {
      position: absolute;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }


    &-content {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(49, 49, 49, 0.85);
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      transform: translateY(100%);
      transition: 0.3s ease-in-out;
      @media (max-width: 991px) {
        background-color: rgba(49, 49, 49, 0.7);
        transform: translateY(0); }

      h3 {
        font: normal normal bold 35px/41px Roboto;
        margin-bottom: 20px;
        @media (max-width: 991px) {
          font-size: 35px;
          line-height: 1.3; } }

      p {
        margin: 0; }

      small {
        font: normal normal 500 12px/20px Gotham Pro; }

      .button-action {
        margin-top: 20px;
        a {
          border-radius: 8px;
          border: 1px solid #ffffff;
          font: normal normal 500 15px/17px Gotham Pro;
          color: #ffffff;
          text-decoration: none;
          padding: 6px 15px;
          display: inline-block;
          @media (max-width: 991px) {
            background-color: #fff;
            color: #1F1F1F; }

          &:hover {
            background-color: #fff;
            color: #1F1F1F; } } } } }

  &-list {
    position: relative;
    overflow: hidden;
    &:hover {
      .works-section-content {
        transform: translateX(0); } }

    .embed-responsive-1by1::before {
      @media (max-width: 991px) {
        padding-top: 45%; }
      @media (max-width: 767px) {
        padding-top: 100%; } } } }


