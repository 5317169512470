.contact {
  &-page {}

  &-section {
    padding: 120px 0 60px;
    @media (max-width: 991px) {
      display: flex;
      min-height: 100vh;
      justify-content: center;
      align-items: center; }

    &-background {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: #0C534D;
        mix-blend-mode: hard-light;
        opacity: 0.54; }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center; } }

    h1 {
      margin-top: 0;
      color: #ffffff;
      font: normal normal 900 70px/81px Gotham Pro;
      text-align: center;
      margin-bottom: 20px;
      @media (max-width: 767px) {
        font-size: 48px;
        line-height: 1.2; }
      @media (max-width: 320px) {
        font-size: 40px; }

      em {
        font: normal normal bold 35px/41px Gotham Pro;
        @media (max-width: 767px) {
          font-size: 30px;
          line-height: 1.2; }
        @media (max-width: 320px) {
          font-size: 26px; } } } }

  &-form {
    &-inner {
      border-radius: 8px;
      box-shadow: inset 6px 6px 10px #00000029;
      background-color: rgba(255,255,255,0.85);

      .form-field {
        border-bottom: 1px solid #9B9B9B;
        padding: 10px 20px;
        display: flex;
        align-items: center;

        label {
          font: normal normal normal 16px/19px Roboto;
          color: #454545;
          margin: 0 10px 0 0; }

        input, textarea {
          flex: 1;
          font: normal normal 16px/19px Roboto;
          border: none;
          background: transparent;
          &::placeholder {
            color: #9B9B9B;
            font-style: italic; } } }

      .textarea-field {
        position: relative;

        textarea {
          min-height: 200px;
          resize: none; }

        i {
          position: absolute;
          right: 20px;
          bottom: 15px;
          color: #9B9B9B;
          font-size: 20px;
          transform: scaleX(-1) rotate(-90deg); } } }

    .form-submit {
      margin-top: 30px;
      text-align: center; } } }


