.team {
  &-section {
    background-color: #171717;
    padding: 60px 0 100px;
    text-align: center;
    @media (max-width: 767px) {
      padding: 40px 0; }

    h2 {
      color: #01FFE8;
      font: normal normal bold 65px/76px Roboto;
      margin-top: 0;
      margin-bottom: 30px;
      @media (max-width: 991px) {
        font-size: 40px;
        margin-bottom: 15px; } } }

  &-list {
    padding: 30px;
    flex: 0 0 20%;
    max-width: 20%;
    @media (max-width: 991px) {
      padding: 30px 15px;
      flex: 0 0 33.33%;
      max-width: 33.33%; }
    @media (max-width: 767px) {
      flex: 0 0 50%;
      max-width: 50%; } }

  &-thumbnail {
    text-align: center;
    img {
      @media (max-width: 991px) {
        max-width: 120px; } } }

  &-name {
    font: normal normal bold 20px/24px Roboto;
    color: #01FFE8;
    margin: 10px 0; }

  &-position {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;

    em {
      font: italic normal normal 16px/1.4 Roboto;
      border-right: 1px solid #ffffff;
      padding-right: 10px; }

    a {
      padding-left: 10px;
      opacity: 0.55;
      display: flex;
      &:hover {
        opacity: 1; }

      .svg {
        width: 15px;
        fill: #ffffff; } } } }

