.services {
  &-section {
    background-color: #0C534D;
    padding-bottom: 30px; }
  &-title {
    text-align: center;
    color: #ffffff;
    padding: 40px 0;
    h2 {
      font: normal normal bold 30px/35px Roboto;
      margin-bottom: 20px;
      margin-top: 0; }
    p {
      margin-bottom: 0;
      font: normal normal normal 18px/26px Roboto;
      @media (max-width: 767px) {
        font-size: 16px;
        line-height: 1.3; } } }

  &-list {
    height: 100%;
    &-thumbnail {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }
    &-content {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: #000000;
        backdrop-filter: blur(11px);
        opacity: 0.58; }
      h3 {
        position: relative;
        margin: 0;
        padding: 35px 70px;
        text-align: center;
        color: #ffffff;
        font: normal normal bold 24px/28px Roboto;
        @media (max-width: 991px) {
          padding: 35px; }
        @media (max-width: 767px) {
          font-size: 16px;
          line-height: 1.3;
          padding: 30px 10px; } } } } }
