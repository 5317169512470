.la-works-page {
  .fixed-background {
    background-color: #1F1F1F;
    @media (max-width: 767px) {
      overflow: hidden; }
    &:before {
      display: none; }

    img {
      object-position: bottom center;
      // @media (max-width: 991px)
      //   opacity: 0.7
      // @media (max-width: 767px)
      //   filter: blur(2px)
 } }      //   transform: scale(1.02)

  .fixed-content {
    height: calc(100vh - 100px);
    color: #ECF4F7;
    display: flex;
    align-items: center;
    padding-bottom: 150px;
    z-index: 1;
    position: relative;

    h1 {
      font: normal normal 900 75px/62px Roboto;
      margin-bottom: 20px;
      @media (max-width: 991px) {
        font-size: 50px;
        line-height: 1.3; }
      @media (max-width: 767px) {
        font-size: 35px; } }

    p {
      font: normal normal normal 20px/24px Roboto; }

    small {
      font: normal normal 500 12px/23px Gotham Pro; } }

  .fixed-section {
    .deco-left,
    .deco-right,
    .deco-main {
      position: absolute;
      @media (max-width: 767px) {
        filter: blur(1px); }
      img {
        width: 100%; } }
    .deco-left {
      top: 30px;
      width: 30%;
      right: 5%;
      &[data-aos] {
        transition-duration: 1.3s; }
      &[data-aos=fade-right] {
        transform: translate3d(-60vw, 0, 0); }
      @media (max-width: 991px) {
        width: 45%;
        top: 30%;
        right: 10%; } }
    .deco-right {
      bottom: -10%;
      right: -10%;
      mix-blend-mode: screen;
      width: 45%;
      @media (max-width: 991px) {
        width: 80%; } }
    .deco-main {
      right: 0;
      bottom: 0;
      width: 41%;
      &[data-aos=fade-left] {
        transform: translate3d(50%, 0, 0); }

      @media (max-width: 991px) {
          width: 80%; } } }


  .works-list-background {
    @media (max-width: 767px) {
      overflow: hidden; }
    img {
      @media (max-width: 991px) {
        // object-position: right
        opacity: 0.7; } } }

  .works-section-wrapper {
    background-color: #F55050;
    position: relative;
    padding-bottom: 90px;
    @media (max-width: 991px) {
      padding-bottom: 30px; }
    > .container {
      transform: translateY(-20px); } }


  .works-list-content {
    padding: 60px;
    min-height: 490px;
    position: relative;
    @media (max-width: 991px) {
      min-height: auto; }
    @media (max-width: 767px) {
      padding: 30px; }

    img {
      width: 100%;
      max-width: 370px; }

    h3 {
      font: normal normal bold 30px/33px Roboto;
      margin: 25px 0; }
    p {
      font-size: 18px; }

    .only-button {
      position: absolute;
      bottom: 30%;
      right: 30%;
      @media (max-width: 991px) {
        left: 0;
        right: 0;
        text-align: center;
        position: relative; }
      // @media (max-width: 767px)
 } }      //   padding: 30px

  .works-list-section {
    .works-list-content {
      padding: 160px 60px;
      @media (max-width: 767px) {
        padding: 80px 30px; } } }

  .button-link {
    display: block;
    padding: 30px;
    background-color: #2D2D2D;
    text-align: center;
    @media (max-width: 767px) {
      padding: 15px; }

    a {
      color: #ffffff;
      text-decoration: none;
      font: normal normal bold 29px/34px Roboto;
      @media (max-width: 767px) {
        font-size: 20px;
        line-height: 1.3; }

      &:hover {
        color: #01FFE8; } } } }
