

.smm-page {
  .normal-scroll {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  .slide {
    &-wrapper {
      &[data-slide="1"] {
        background-color: #DE0404;

        [data-aos^=zoom][data-aos^=zoom] {
          opacity: 1; }

        [data-aos=fade-up] {
          transform: translate3d(0, 120%, 0); }

        .phone-deco {
          width: 37%;
          position: absolute;
          right: 5%;
          bottom: 0;
          @media (max-width: 991px) {
            width: 60%; }
          @media (max-width: 767px) {
            width: 100%; } }


        .peoples-deco {
          position: absolute;
          width: 50%;
          top: 35%;
          right: 24%;
          @media (max-width: 991px) {
            top: auto;
            bottom: 25%;
            right: 0;
            left: 0;
            width: 100%;
            img {
              filter: blur(2px); } } }


        ._content {
          z-index: 1;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          padding: 100px 0 60px;

          &-wrapper {
            align-items: flex-end;
            @media (max-width: 991px) {
              align-items: center; } } }
        h1 {
          font: normal normal 900 55px/50px Cera Pro;
          margin-top: 30px;
          @media (max-width: 767px) {
            margin-top: 0;
            font-size: 40px;
            line-height: 1; } }

        h3 {
          font: normal normal 500 25px/35px Roboto;
          @media (max-width: 767px) {
            font-size: 20px;
            line-height: 1.4; } }

        .red-box {
          background-color: #DE0404;
          color: #ffffff;
          padding: 30px;
          box-shadow: 3px 3px 3px rgba(0,0,0,0.35);
          border-radius: 8px;
          margin-top: 80px;
          @media (max-width: 767px) {
            margin-top: 30px;
            padding: 15px 20px; }
          h2 {
            color: #1F1F1F;
            font: normal normal 700 35px/46px Roboto;
            @media (max-width: 767px) {
              font-size: 28px; } }
          ul {
            margin: 0;
            list-style: none;
            padding-left: 30px;
            li {
              font: normal normal 400 20px/24px Roboto;
              margin-bottom: 5px;
              @media (max-width: 767px) {
                font-size: 16px;
                margin-bottom: 10px; }
              img {
                margin-left: -30px; } } } } }

      &[data-slide="2"] {
        background-color: #CE001B;
        ._content {
          // @media (max-width: 767px)
 }          //   padding-bottom: 0
        .teen-main-image {
          position: absolute;
          width: 55%;
          bottom: -1%;
          left: 0;
          transition: 0.3s;
          @media (max-width: 991px) {
            width: 100%;
            img {
              filter: blur(3px); } } }
        .bottom-right-deco {
          position: absolute;
          bottom: -2%;
          right: 0;
          width: 25%;
          transition: 0.3s; }
        .bottom-left-deco {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 20%; }

        .top-left-deco {
          position: absolute;
          left: -2%;
          transform: rotate(25deg);
          top: 5%;
          width: 13%;
          transition: 0.3s; }
        .top-deco {
          position: absolute;
          left: 18%;
          width: 12%;
          top: 5%;
          transform: rotate(20deg);
          transition: 0.3s; }
        .top-right-deco {
          position: absolute;
          right: 5%;
          width: 20%;
          top: 9%;
          transition: 0.3s; }
        .buble-right-deco {
          position: absolute;
          right: 3%;
          width: 15%;
          top: 20%;
          transition: 0.3s;
          @media (max-width: 991px) {
            top: 40%; }
          @media (max-width: 767px) {
            top: 30%; } }
        .buble-left-deco {
          position: absolute;
          left: 13%;
          width: 10%;
          top: 22%;
          transform: rotate(-15deg);
          transition: 0.3s;
          @media (max-width: 991px) {
            top: 40%; }
          @media (max-width: 767px) {
            top: 30%; } }
        .like-deco {
          position: absolute;
          bottom: 5%;
          left: 45%;
          width: 10%;
          transition: 0.3s;
          @media (max-width: 991px) {
            img {
              filter: blur(3px); } } }


        .move {
          .teen-main-image {
            left: 2%;
            bottom: 0; }
          .bottom-right-deco {
            right: 1%;
            transform: rotate(-15deg); }
          .top-left-deco {
            transform: rotate(15deg);
            top: 7%; }
          .top-deco {
            left: 16%;
            top: 7%; }
          .top-right-deco {
            transform: rotate(-15deg);
            width: 18%;
            top: 10%; }
          .buble-right-deco {
            transform: scale(1.02) rotate(-15deg);
            right: 2%;
            @media (max-width: 991px) {
              top: 38%; }
            @media (max-width: 767px) {
              top: 28%; } }
          .buble-left-deco {
            left: 10%;
            width: 12%;
            top: 18%;
            @media (max-width: 991px) {
              top: 38%; }
            @media (max-width: 767px) {
              top: 28%; } }
          .like-deco {
            bottom: 6%;
            left: 46%; } }

        .move2 {
          .teen-main-image {
            left: 3%;
            bottom: -1%; }
          .bottom-right-deco {
            right: 0;
            transform: rotate(-15deg);
            width: 28%; }
          .top-left-deco {
            transform: rotate(20deg);
            top: 6%; }
          .top-left-deco {
            left: -1%;
            transform: rotate(15deg);
            top: 7.5%; }
          .top-deco {
            left: 18%; }
          .top-right-deco {
            transform: rotate(5deg);
            width: 18%;
            top: 8%;
            right: 7%; }

          .buble-right-deco {
            transform: scale(1) rotate(0);
            right: 4%;
            top: 22%;
            @media (max-width: 991px) {
              top: 38%; }
            @media (max-width: 767px) {
              top: 28%; } }
          .buble-left-deco {
            left: 10%;
            width: 12%;
            top: 18%;
            transform: rotate(5deg);
            @media (max-width: 991px) {
              top: 38%; }
            @media (max-width: 767px) {
              top: 28%; } }
          .like-deco {
            transform: rotate(-5deg); } }


        .slider-wrapper {
          position: relative;
          .frame-slide {
            width: 40%;
            border-radius: 19px;
            border: 4px solid #ffffff;
            height: 160px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            margin: auto;


            &:before {
              content: '';
              width: 0px;
              height: 0px;
              border-left: 16px solid transparent;
              border-right: 16px solid transparent;
              border-top: 25px solid #ffffff;
              position: absolute;
              left: 0;
              right: 0;
              margin: auto;
              top: 0; }

            &:after {
              content: '';
              width: 0px;
              height: 0px;
              border-left: 9px solid transparent;
              border-right: 9px solid transparent;
              border-bottom: 15px solid #ffffff;
              position: absolute;
              left: 0;
              right: 0;
              margin: auto;
              bottom: 0; } } }

        .years-content-slider {
          .slider {
            text-align: center;
            margin: 60px 0 0;
            @media (max-width: 991px) {
              .col-12 {
                margin: 40px 0 0!important; } }
            @media (max-width: 767px) {
              margin: 0; }
            h4 {
              font: normal normal 900 25px/1 Roboto;
              @media (max-width: 767px) {
                font-size: 20px; }
              span {
                color: #31FF49;
                font: normal normal 900 28px/1 Roboto;
                @media (max-width: 767px) {
                  font-size: 25px; } } }
            h3 {
              font: normal normal 900 45px/59px Roboto;
              color: #31FF49;
              margin-bottom: 0;
              margin-top: 10px;
              @media (max-width: 767px) {
                font-size: 30px;
                line-height: 1; } }

            p {
              margin: 0;
              font: normal normal 400 14px/5px Roboto;
              @media (max-width: 767px) {
                line-height: 1.3; } } } }

        .slider-wrapper {
          position: relative;
          .frame-slide {
            width: 40%;
            border-radius: 19px;
            border: 4px solid #ffffff;
            height: 160px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            margin: auto;
            @media (max-width: 767px) {
              border: 2px solid #ffffff;
              height: calc(100% + 30px);
              top: 65%; }


            &:before {
              content: '';
              width: 0px;
              height: 0px;
              border-left: 16px solid transparent;
              border-right: 16px solid transparent;
              border-top: 25px solid #ffffff;
              position: absolute;
              left: 0;
              right: 0;
              margin: auto;
              top: 0; }

            &:after {
              content: '';
              width: 0px;
              height: 0px;
              border-left: 9px solid transparent;
              border-right: 9px solid transparent;
              border-bottom: 15px solid #ffffff;
              position: absolute;
              left: 0;
              right: 0;
              margin: auto;
              bottom: 0; } } }

        .years-slider {
          position: relative;
          opacity: 0;
          visibility: hidden;
          &.slick-initialized {
            opacity: 1;
            visibility: visible; }
          .slick-track {
            display: flex !important; }

          .slick-slide {
              height: inherit !important;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center; }
          .slider {
            text-align: center;
            padding-top: 30px;
            .dots {
              width: 12px;
              height: 12px;
              border-radius: 50%;
              background-color: #ffffff;
              opacity: 0.5;
              margin-bottom: 25%;
              @media (max-width: 767px) {
                margin-bottom: 15%; } }
            h3 {
              font: normal normal 900 40px/45px Roboto;
              margin: 0;
              @media (max-width: 767px) {
                font-size: 25px;
                line-height: 1; } }

            p {
              font: normal normal 400 20px/45px Roboto;
              margin: 0;
              opacity: 0;
              visibility: hidden;
              transition: 0.3s;
              @media (max-width: 767px) {
                font-size: 14px;
                line-height: 1; } }

            &.slick-center {
              h3 {
                font-size: 75px;
                @media (max-width: 767px) {
                  font-size: 45px; } }
              p {
                opacity: 1;
                visibility: visible; } } }

          .slick-list {
            padding: 0!important; }

          .slick-arrow {
            background-color: transparent;
            border: none;
            padding: 0;
            position: absolute;
            right: -50px;
            top: 50%;
            transform: translateY(-50%);
            outline: none!important;
            z-index: 1;
            cursor: pointer;
            @media (max-width: 991px) {
              right: 0; }
            @media (max-width: 767px) {
              top: 60%; }

            i {
              display: block;
              width: 26px;
              height: 95px;
              background-position: 0 0;
              background-repeat: no-repeat;
              background-size: contain;
              margin: 0 auto;
              overflow: hidden;
              text-indent: -999px;
              background-image: image('slick-arrow.svg');
              @media (max-width: 767px) {
                width: 20px;
                height: 60px; } }
            &:hover {
              i {
                background-image: image('slick-arrow-hover.svg'); } }

            &.slick-prev {
              right: auto;
              left: -50px;
              @media (max-width: 991px) {
                left: 0; }
              i {
                transform: rotate(180deg); } }

            &.slick-disabled {
              opacity: 0.5;
              &:hover {
                i {
                  background-image: image('slick-arrow.svg'); } } } } } }

      &[data-slide="3"] {
        background-color: #AC0C21;

        .list-thumbnail {
          padding: 0 7px 14px;
          img {
            width: 100%; }
          &-small {
            padding: 0 7px 14px;
            img {
              width: 100%; }
            @media (max-width: 991px) {
              flex: 0 0 50%;
              max-width: 50%;
              &:last-child {
                flex: 0 0 100%;
                max-width: 100%; } } }
          &-inner {
            padding: 0; } }
        ._content {
          .row {
            margin: 0 -7px; } } } } } }
