body {
  font-family: 'Roboto', sans-serif;
  color: #2D2D2D;
  letter-spacing: 0.03em; }
a {
  transition: 0.4s;
  outline: none; }

.overflow-hidden {
  overflow: hidden!important; }

img {
  max-width: 100%; }

.pl-8 {
  padding-left: 5rem !important; }

html:not(.no-js) [data-aos^=fade][data-aos^=fade] {
  opacity: 1!important; }

.main {
  padding-bottom: 30px;
  @media (max-width: 991px) {
    padding-bottom: 0; } }

.container-fluid {
  max-width: 1440px; }

.button-outline {
  font: normal normal 500 20px/23px Gotham Pro;
  border: 1px solid #FFFFFF;
  border-radius: 8px;
  padding: 6px 40px;
  text-align: center;
  min-width: 150px;
  background-color: transparent;
  color: #ffffff;
  text-decoration: !important;
  &:hover {
    background-color: #fff;
    color: #1F1F1F; } }

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  display: none; }

[type="radio"]:checked + .checked,
[type="radio"]:not(:checked) + .checked {
  position: relative;
  width: 12px; }

[type="radio"]:checked + .checked:before,
[type="radio"]:not(:checked) + .checked:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: #fff; }

[type="radio"]:checked + .checked:after,
[type="radio"]:not(:checked) + .checked:after {
  content: '';
  width: 8px;
  height: 8px;
  background: #666666;
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease; }

[type="radio"]:not(:checked) + .checked:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0); }

[type="radio"]:checked + .checked:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1); }

 :focus {
  outline: none; }

.sosmed-wrapper {
  @media (max-width: 767px) {
    .col-md-4 {
      margin-bottom: 30px; } } }
.sosmed-title {
  display: flex;
  background: #006691;
  color: #ffffff;
  padding: 10px 15px;
  align-items: center;
  font-size: 14px;
  justify-content: space-between;
  .title-left {
    display: flex;
    align-items: center; }
  i {
    font-size: 18px;
    margin-right: 10px; }
  a {
    color: #ffffff;
    text-decoration: none;
    font-size: 14px;
    flex: none; } }

.form-inline {
  position: relative;
  @media (max-width: 991px) {
    margin-bottom: 15px;
    .form-control {
      margin: 0; } }
  input.form-control {
    border-radius: 30px;
    text-align: center;
    width: 200px;
    border: none;
    margin-left: 20px;
    padding-left: 42px;
    height: 33px;
    background-color: #f7f7f7;
    border-radius: 2px;
    @media (max-width: 991px) {
      width: 100%;
      text-align: left;
      height: 45px;
      margin-left: 0; }
    &:focus {
      box-shadow: none; } }
  button {
    background-color: transparent;
    position: absolute;
    left: 20px;
    padding: 0 10px;
    height: 33px;
    @media (max-width: 991px) {
      left: 0; }
    &:focus {
      box-shadow: none; } }
  img {
    width: 20px; } }

#modalVideo {
  .close {
    position: absolute;
    z-index: 9;
    color: #ffffff;
    top: -40px;
    right: 0;
    outline: 0;
    width: 20px;
    @media (min-width: 1600px) {
      width: 30px; } }

  .modal-dialog {
    width: 92%;
    max-width: 950px;
    padding: 0;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
    @media (min-width: 1600px) {
      width: calc(100% - 60px); }
    @media (max-width: 767px) {
      width: calc(100% - 30px); } }

  .modal-body {
    position: relative;
    overflow: hidden;
    background-color: #000000;
    width: 100%;
    padding: 0;
    &:before {
      content: '';
      display: block;
      padding-top: 52.7%; }

    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0; } } }
