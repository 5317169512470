@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro-Black.eot');
  src: url('../fonts/GothamPro-Black.eot?#iefix') format('embedded-opentype'), url('../fonts/GothamPro-Black.woff2') format('woff2'), url('../fonts/GothamPro-Black.woff') format('woff'), url('../fonts/GothamPro-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap; }


@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro-Bold.eot');
  src: url('../fonts/GothamPro-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/GothamPro-Bold.woff2') format('woff2'), url('../fonts/GothamPro-Bold.woff') format('woff'), url('../fonts/GothamPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro-Medium.eot');
  src: url('../fonts/GothamPro-Medium.eot?#iefix') format('embedded-opentype'), url('../fonts/GothamPro-Medium.woff2') format('woff2'), url('../fonts/GothamPro-Medium.woff') format('woff'), url('../fonts/GothamPro-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Cera Pro';
  src: url('../fonts/CeraPro-Black.eot');
  src: url('../fonts/CeraPro-Black.eot?#iefix') format('embedded-opentype'), url('../fonts/CeraPro-Black.woff2') format('woff2'), url('../fonts/CeraPro-Black.woff') format('woff'), url('../fonts/CeraPro-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap; }


@font-face {
  font-family: 'Cera Pro';
  src: url('../fonts/CeraPro-Light.eot');
  src: url('../fonts/CeraPro-Light.eot?#iefix') format('embedded-opentype'), url('../fonts/CeraPro-Light.woff2') format('woff2'), url('../fonts/CeraPro-Light.woff') format('woff'), url('../fonts/CeraPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

