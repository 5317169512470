//
// Header Style
//
.header {
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  position: fixed;
  z-index: 10;
  width: 100%;
  top: 0;
  backdrop-filter: blur(29px);

  > .container {
    height: 80px; }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between; }

  .logo {
    max-width: 95px;
    flex: 1;
    z-index: 999; }

  .main-menu {
    display: block;
    padding: 0;
    margin: 0;
    li {
      display: block;
      a {
        display: inline-block;
        font-weight: bold;
        font-size: 60px;
        margin: 10px 0;
        letter-spacing: 0.03em;
        -webkit-text-stroke: 3px #ffffff;
        text-stroke: 3px #ffffff;
        color: transparent;
        text-decoration: none;
        text-transform: uppercase;
        @media (max-width: 991px) {
          padding: 0 30px; }
        @media (max-width: 767px) {
          font-size: 35px;
          -webkit-text-stroke: 1px #ffffff;
          text-stroke: 1px #ffffff; }
        &:hover {
          color: #ffffff; } } } }

  .burger {
    background-color: transparent;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    display: block;
    height: 60px;
    padding: 0;
    position: relative;
    width: 56px;
    z-index: 1000;
    transition: all 300ms ease-out;
    outline: none;
    margin-right: -15px; }

  .burger-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }

  .x,
  .y,
  .z {
    position: absolute;
    margin: auto;
    top: 0px;
    bottom: 16px;
    left: 0;
    right: 0;
    background: #E6E7E8;
    transition: all 200ms ease-out; }

  .x,
  .y,
  .z {
    width: 24px;
    border-radius: 5px;
    height: 4px; }

  .y {
    top: 18px; }

  .z {
    top: 35px; }

  .collapse-icon {
    background: #ffffff;
    top: 17px;
    width: 30px;
    transition: all 70ms ease-out; }

  .rotate30 {
    transform: rotate(30deg);
    transition: all 50ms ease-out; }

  .rotate150 {
    transform: rotate(150deg);
    transition: all 50ms ease-out; }

  .rotate45 {
    transform: rotate(45deg);
    transition: all 100ms ease-out; }

  .rotate135 {
    transform: rotate(135deg);
    transition: all 100ms ease-out; }

  .menu-bg {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: #0C534D;
    backdrop-filter: blur(45px);
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    transition: all 300ms cubic-bezier(0.000, 0.995, 0.990, 1.000); }

  .menu-bg.animate {
    opacity: 0.88;
    visibility: visible;
    transition: all 400ms cubic-bezier(0.000, 0.995, 0.990, 1.000); }

  .menu-navigation {
    width: 100%;
    max-width: 720px;
    opacity: 0;
    visibility: hidden;
    animation-name: zoomOut;
    animation-duration: .3s;
    position: absolute;
    z-index: 102;
    left: 0;
    right: 0;
    margin: auto;
    height: auto;
    top: 80px; }


  @-webkit-keyframes zoomOut {
    from {
      opacity: 1; }

    50% {
      opacity: 0;
      transform: scale3d(.3, .3, .3); }

    to {
      opacity: 0; } }


  @keyframes zoomOut {
    from {
      opacity: 1; }

    50% {
      opacity: 0;
      transform: scale3d(.3, .3, .3); }

    to {
      opacity: 0; } }


  .menu-navigation.animate {
    animation-name: zoomIn;
    animation-duration: .4s;
    opacity: 1;
    visibility: visible; }

  @-webkit-keyframes zoomIn {
    from {
      opacity: 0;
      transform: scale3d(.3, .3, .3); }

    50% {
      opacity: 1; } }


  @keyframes zoomIn {
    from {
      opacity: 0;
      transform: scale3d(.3, .3, .3); }

    50% {
      opacity: 1; } } }
