.services-detail {
  position: relative;
  &-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    @media (max-width: 767px) {
      background-color: #0C534D; }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @media (max-width: 767px) {
        filter: blur(2px);
        opacity: 0.2;
        object-position: bottom right;
        mix-blend-mode: hard-light; } } }

  &-content {
    padding: 80px 0;
    color: #ffffff;
    @media (max-width: 767px) {
      padding: 50px 0; }
    h3 {
      font: normal normal 900 32px/38px Roboto; }
    p {
      font: normal normal normal 18px/28px Roboto;
      margin: 0; } } }

.content-position {
  &-right {
    .row {
      justify-content: flex-end; }
    @media (max-width: 767px) {
      .services-detail-background {
        img {
          object-position: bottom left; } } } } }

