.masthead {
  &-section {
    position: relative; }
  &-background {
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      // background: #0d5e58
      background: #01FFE8;
      // backdrop-filter: brightness(115%)
      mix-blend-mode: hard-light;
      mix-blend-mode: darken; }
    img {
      width: 100%;
      height: 100vh;
      object-fit: cover;
      @media (max-width: 991px) {
        object-position: 87% 0; }
      @media (max-width: 767px) {
        object-position: 80% 0; } } }

  &-content {
    position: absolute;
    color: #ffffff;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    .container {
      height: 100%;
      position: relative; }
    &-inner {
      position: absolute;
      top: 30%;
      left: 10%;
      @media (max-width: 991px) {
        left: 15px;
        right: 15px;
        top: 18%; } }
    h1 {
      font: normal normal 900 100px/90px Roboto;
      @media (max-width: 767px) {
        font-size: 58px;
        line-height: 1.1; }
      em {
        display: block;
        font-style: normal;
        font: normal normal 900 65px/76px Roboto;
        @media (max-width: 767px) {
          font-size: 42px;
          line-height: 1.1; } } }
    h3 {
      font: normal normal bold 25px/29px Roboto;
      @media (max-width: 767px) {
        font-size: 16px;
        line-height: 1.1; } } } }
