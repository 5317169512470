.brands-page {
  .brands {
    &-section {
      padding: 150px 0 60px;
      background: transparent linear-gradient(0deg, #C1C1C1 0%, #C1C1C1 85%, #616161 100%);
      @media (max-width: 991px) {
        padding-bottom: 40px; }
      h2 {
        margin-bottom: 60px;
        @media (max-width: 991px) {
          margin-bottom: 40px; } } } } }
