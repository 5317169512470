//
// Footer Style
//
.footer {
  background-color: #1F1F1F;
  padding: 20px 0 10px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  @media (max-width: 991px) {
    position: relative; }

  p {
    margin: 0; }

  &-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
    @media (max-width: 991px) {
      flex-direction: column; }

    &-left {
      display: flex;
      padding-right: 20px;
      flex: 0 0 65%;
      @media (max-width: 991px) {
        flex-direction: column;
        padding: 0;
        flex: 0 0 100%; }
      @media (max-width: 767px) {
        width: 100%; }

      p {
        color: #01FFE8;
        font: normal normal normal 12px/15px Roboto;
        text-transform: uppercase;
        @media (max-width: 991px) {
          text-align: center;
          margin-bottom: 10px; } }

      ul {
        list-style: none;
        display: flex;
        padding: 0;
        margin: 0;
        @media (max-width: 991px) {
          margin-bottom: 20px; }
        @media (max-width: 767px) {
          justify-content: space-between; }

        li {
          display: flex;
          align-items: center;
          justify-content: center;
          @media (max-width: 991px) {
            align-items: stretch; }

          &:first-child {
            a {
              @media (max-width: 991px) {
                border-left: none; }
              @media (max-width: 767px) {
                padding-left: 0; } } }
          &:last-child {
            a {
              @media (max-width: 767px) {
                padding-right: 0; } } } }

        a {
          color: #01FFE8;
          font: normal normal normal 12px/15px Roboto;
          padding: 0 20px;
          border-left: 1px solid #01FFE8;
          -webkit-text-stroke: 0.8px transparent;
          text-stroke: 0.8px transparent;
          text-decoration: none;
          letter-spacing: 0.6px;
          text-transform: uppercase;
          @media (max-width: 991px) {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center; }
          @media (max-width: 767px) {
            padding: 0 15px; }

          &:hover {
            -webkit-text-stroke: 0.8px #01FFE8;
            text-stroke: 0.8px #01FFE8; } }

        li:first-child {
          a {
            margin-left: 20px;
            @media (max-width: 991px) {
              margin-left: 0; } } } } }

    &-right {
      display: flex;
      flex: 0 0 35%;
      @media (max-width: 767px) {
        width: 100%;
        flex: 0 0 100%;
        justify-content: space-between; }
      .socmed {
        p {
          margin-left: 20px;
          @media (max-width: 991px) {
            margin-left: 0; } } }

      .contact,
      .socmed {
        display: flex;
        align-items: center;
        @media (max-width: 767px) {
          flex-direction: column; }

        p {
          font: normal normal bold 10px/11px Roboto;
          color: #ffffff;
          opacity: 0.55;
          text-transform: uppercase;
          @media (max-width: 991px) {
            margin-right: 20px; }
          @media (max-width: 767px) {
            text-align: left;
            margin-bottom: 10px;
            margin-right: 0;
            width: 100%; } }

        ul {
          list-style: none;
          display: flex;
          padding: 0;
          margin: 0;
          // width: 100%

          li {
            margin-left: 20px;
            @media (max-width: 991px) {
              margin-right: 20px;
              margin-left: 0; }

            a {
              opacity: 0.55;
              &:hover {
                opacity: 1; } } }

          .svg {
            fill: #ffffff;
            width: 20px;
            @media (max-width: 767px) {
              width: 25px; } } } }

      .contact {

        // .svg
        //   width: 20px
        //   @media (max-width: 767px)
 }        //     width: 25px

      .socmed {
        li:last-child {
          @media (max-width: 991px) {
            margin-right: 0; } }

        // .svg
        //   height: 15px
        //   object-fit: contain
        //   width: 100%
        //   @media (max-width: 767px)
 } } } }        //     height: 20px



