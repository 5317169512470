.fixed {
  &-section {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0; }
  &-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: #0C534D;
      backdrop-filter: blur(5px) brightness(114%) opacity(0.75);
      mix-blend-mode: hard-light; }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: bottom center; } }

  &-content {
    padding: 150px 0 100px;
    width: 100%;
    h1 {
      color: #ffffff;
      margin: 0;
      font: normal normal 900 55px/64px Gotham Pro;
      @media (max-width: 991px) {
        font-size: 50px;
        line-height: 1.3; }
      @media (max-width: 767px) {
        font-size: 35px; }
      em {
        font: normal normal 900 35px/64px Gotham Pro;
        @media (max-width: 991px) {
          font-size: 30px;
          line-height: 1.3; }
        @media (max-width: 767px) {
          font-size: 20px;
          &:first-child {
            display: block; }
 } } } } }          //   display: block

