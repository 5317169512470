
.skda-page {
  // .audioplayer
  //   border: 1px solid #FA4934
  //   background: #FA4934
  //   border-radius: 8px
  //   position: absolute
  // .audioplayer:not(.audioplayer-playing)
  //   .audioplayer-playpause
  //     border: 1px solid #ffffff
  //     a
  //        border-left: 12px solid #ffffff
  // .audioplayer-volume-adjust div div
  //   background-color: #36C800
  // .audioplayer-bar
  //   padding: 0 2px
  // .audioplayer-bar::before
  //   height: 6px
  //   top: 3px
  //   width: calc(100% - 6px)!important
  // .audioplayer-bar-played
  //   height: 6px
  //   background: #FA4934
  //   top: 3px
  //   left: 3px
  //   right: 3px
  //   border-radius: 8px
  //   // width: calc(100% - 6px)!important
  // // .audioplayer-bar > div
  // //   top: 3px
  // .audioplayer-bar-loaded
  //   background: #ffffff
  //   height: 12px
  //   top: 0
  //   border-radius: 8px
  // .audioplayer-time
  //   color: #ffffff
  // .audioplayer-bar-played::after
  //   background: #1F1F1F
  //   top: -2px
  //   width: 10px
  //   height: 10px
  //   margin-right: 0
  //   right: 0
  //   // left: -3px
  // .audioplayer:not(.audioplayer-playing)
  //   .audioplayer-bar-played::after
  //     border: 2px solid #1F1F1F
  // .audioplayer:not(.audioplayer-mute)
  //   .audioplayer-volume-button
  //     a
  //       background-color: #ffffff
  //       &:after

  //         border: 6px double #ffffff
  //       &:before
  //         border-right: 9px solid #ffffff
  .normal-scroll {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  .slide {
    &-wrapper {
      &[data-slide="1"] {
        background-color: #DE0404;
        ._background {
          width: calc(100% + 150px);
          img {
            object-position: top left;
            @media (max-width: 991px) {
              object-position: 80%; } } }
        ._content-wrapper {
          @media (max-width: 991px) {
            text-align: center; } } }


      &[data-slide="2"] {
        background-color: #FF4A35;
        .container {
          z-index: 1;
          position: relative; }

        ._background {
          img {
            @media (max-width: 991px) {
              object-fit: contain;
              object-position: bottom left; } } }
        h4 {
          font: normal normal 900 26px/35px Roboto;
          span {
            font: normal normal 300 22px/35px Roboto; } }
        .deco-notes {
          &-1,&-2 {
            position: absolute;
            z-index: 1; }

          &-1 {
            top: 7%;
            left: 4%; }
          &-2 {
            top: 35%;
            left: 42%; } }

        ._background {
          width: 70%;
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 1;
          top: auto;
          @media (max-width: 991px) {
            width: 100%; } }

        .deco-bottom {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 40%;
          img {
            width: 100%; } } }

      &[data-slide="3"] {
        ._content-wrapper {
          display: block;
          padding-top: 20%;
          @media (max-width: 991px) {
            padding-top: 0; } }

        .audio-deco {
          position: absolute;
          bottom: 0;
          left: 10%;
          width: 42%;
          @media (max-width: 991px) {
            left: 0;
            width: 50%; } }

        .model-deco {
          position: absolute;
          bottom: -3%;
          right: 0;
          width: 65%;
          @media (max-width: 991px) {
            width: 100%;
            right: -5%; } }
        ._content {
          @media (max-width: 767px) {
            padding: 40px 0; } }

        h3 {
          color: #DE0404;
          font: normal normal 900 80px/94px Roboto;

          small {
            font: normal normal 900 30px/35px Roboto;

            &:last-child {
              font: normal normal 900 35px/41px Roboto;
              margin-left: -10%;
              @media (max-width: 767px) {
                display: block;
                margin-left: 0; } } } } } } } }
